<template>
  <div class="container">
    <div class="query-title">

      <div class="demo-input-suffix">

        <p class="titname">加群记录列表</p>

        <!-- <el-button type="primary" class="tit-button" @click="addBtn">新 增</el-button> -->
      </div>
    </div>
    <div class="table-container">
      <el-table highlight-current-row :header-cell-style="{ background: 'rgba(113,183,255,0.4)', color: '#606266', }"
        :data="tabledata" :height="tableHeight" border style="width: 100%">
        <el-table-column type="index" align='center' width="55" label="序号"></el-table-column>
        <el-table-column prop="createTime" label="加群时间" align="center"></el-table-column>
        <el-table-column prop="ggroupUserEntity.phone" label="手机号码" align="center"></el-table-column>

        <el-table-column prop="ggroupUserEntity.name" label="姓名" align="center"></el-table-column>
        <el-table-column prop="ggroupUserEntity.workerType" label="身份" align="center">
          <template slot-scope="scope">
            <p v-if="scope.row.ggroupUserEntity.workerType==0">工人</p>
            <p v-if="scope.row.ggroupUserEntity.workerType==1">班组</p>
            <p v-if="scope.row.ggroupUserEntity.workerType==3">工程管理</p>

          </template>
        </el-table-column>
        <el-table-column prop="eworkGroupEntity.workerTypeTwo" label="群工种" align="center"></el-table-column>
        <el-table-column prop="ggroupUserEntity.scale" label="班组规模" align="center"></el-table-column>


      </el-table>
    </div>
    <div class="fye">
      <pagintion :ToTal="total" :pageNums="pagenums" @dsydata="DsyData" @dstdata="DstData" />
    </div>
  </div>
</template>
<script>
  import pagintion from "@/components/pagintion/pagintion.vue";
  import {gusergroupgetList} from "@/api/api";
  export default {
    name: "clusteringrecord",
    components: {
      pagintion,
    },
    props: {},
    data() {
      return {
        specimenname: "",
        tabledata: [],
        tableHeight: window.innerHeight - 235,
        dialogData: [],
        pageNum: 1,
        pageSize: 20,

        total: null,
        pagenums: null,
        dialogVisible: false,
        title: "",
      };
    },
    computed: {},
    watch: {},
    created() {
      window.addEventListener('resize', this.getHeight)
    },
    destroyed() {
      window.removeEventListener('resize', this.getHeight)
    },
    beforeDestroy() {},
    mounted() {

      this.Datalist();
    },
    methods: {
      Backbtn() {
        this.$router.go(-1)
      },
      getHeight() {
        this.tableHeight = window.innerHeight - 235;
      },

      addBtn() {
        this.dialogData = [];
        this.dialogVisible = true;
        this.title = "新增";
      },
      Getcancel() {
        this.dialogVisible = false;
      },
      addeteRmine() {
        this.dialogVisible = false;
        this.Datalist();
      },
      DsyData(data) {
        this.pagenums = data.dsy;
        this.pageNum = data.dsy;
        this.Datalist()
      },
      DstData(data) {
        this.pageSize = data.dst;
        this.Datalist()
      },

      edit(row, index) {
        this.dialogVisible = true;
        this.dialogData = [row, "edit"];
        this.title = "编辑";
      },
      deletes(row) {

        this.$confirm("此操作将永久删除, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then((action) => {
          if (action === "confirm") {
            let params = [row.id]

            eworkgroupdelete(params).then((res) => {
              if (res.status == 200) {
                this.Datalist();
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.message,
                  type: "success",
                });
              }
            }).catch((err) => {
              this.$message.error("删除失败！");
            });
          }
        }).catch(() => {});

      },

      Datalist() {
        let params = {

          size:this.pageSize,
          current:this.pageNum,
        }
        gusergroupgetList(params).then((res) => {
          console.log(res)

          this.tabledata = res.data.list;
          //this.tabledata = res.data;
          this.total = Number(res.data.total);

        }).catch((error) => {
          this.$message.error("获取数据失败！");
        });
      },
    },
  };
</script>

<style scoped>

</style>
